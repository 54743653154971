import React, { FC, Fragment } from 'react';

import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, Title, Description, TextTitle, Space, TextDescription, TextWrapper,
} from './WidgetEight.styled';

const WidgetEight: FC<IWidget<null, null>> = ({
  title, header, text,
}) => (
  <Wrapper withPaddings widePaddings>
    <Space />
    <Title>{title}</Title>
    <Description>{header}</Description>
    {text?.map((item) => (
      <Fragment key={item.id}>
        <TextTitle>{item.title}</TextTitle>
        <TextWrapper>
          {item?.text?.trim()?.split(/\r?\n/)?.map((qwe) => (
            <TextDescription key={qwe}>
              {qwe}
            </TextDescription>
          ))}
        </TextWrapper>
      </Fragment>
    ))}
  </Wrapper>
);

export default WidgetEight;
