import { IComponentWidgetText } from 'interfaces/widgets';
import React, { FC } from 'react';

import {
  Container, Title, Description, TextWrapper, TextTitle,
} from './Overlay.styled';

interface IOverlay {
  title?: string;
  description?: string;
  text?: IComponentWidgetText[];
  wideMenu?: boolean;
}

const Overlay: FC<IOverlay> = ({
  title,
  description,
  text,
  wideMenu,
}) => ((title?.length || description?.length) ? (
  <Container withPaddings>
    {title && (
      <Title className={wideMenu ? 'wideMenu' : ''}>{title}</Title>
    )}
    {description && (
      <Description>{description}</Description>
    )}
    {Boolean(text?.length) && (
      <TextWrapper>
        {text?.map((item) => (
          <TextTitle key={item.id}>
            {item.title}
          </TextTitle>
        ))}
      </TextWrapper>
    )}
  </Container>
) : null);

export default Overlay;
