import React, {
  FC, useRef, createRef, useEffect, useState,
} from 'react';

import { IMediaImage, IMediaVideo } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import { ScrollableSlider } from 'UI';

import Slide from './Slide/Slide';
import { Wrapper, SlidesList, Container } from './WidgetFive.styled';
import Overlay from './Overlay/Overlay';

const WidgetFive: FC<IWidget<null, IMediaImage | IMediaVideo>> = ({
  slide, title, header, text,
}) => {
  const sliderRef = useRef();
  /**
   * Slides refs
   */
  const slideRefs = useRef(slide.map(() => createRef<HTMLDivElement>()));
  const videoRefs = useRef(slide.map(() => createRef<HTMLVideoElement>()));
  const menuRef = useRef<HTMLDivElement>();

  const [wideMenu, setWideMenu] = useState<boolean>(false);

  let isVisible = false;
  let activeSlide = 0;

  const voidFunction = () => {};

  const playVideo = async (index: number) => {
    const video = videoRefs.current?.[index].current;
    if (!video || !isVisible) return;

    const isPlaying = video.currentTime > 0
      && !video.paused
      && !video.ended
      && video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      await video.play();
    }
    video.poster = '';
  };

  const pauseVideo = (index: number) => {
    const video = videoRefs.current?.[index].current;
    if (!video || !isVisible) return;

    video.pause();
  };

  const pauseSlides = () => {
    slideRefs.current?.forEach((item, index) => {
      pauseVideo(index);
    });
  };

  const handleChangeSlide = (index: number = activeSlide) => {
    if (!isVisible) return;

    activeSlide = index;

    slideRefs.current?.forEach(({ current }, key) => {
      if (key !== index) {
        current?.classList.remove('active');
        pauseVideo(key);
      } else {
        current?.classList.add('active');
        playVideo(key).then(voidFunction, voidFunction);
      }
    });
  };

  const handleVisibility = (visibility: boolean) => {
    if (isVisible === visibility) return;

    isVisible = visibility;

    if (!visibility) {
      pauseSlides();
    } else handleChangeSlide(activeSlide);
  };

  const getItems = (): string[] => slide.map((item) => item.title);

  useEffect(() => {
    const menu = menuRef.current;

    if (menu && menu.offsetWidth > 1000) {
      setWideMenu(true);
    }
  }, []);

  return (
    <Container gridOnly widePaddings>
      <Wrapper>
        <ScrollableSlider
          ref={sliderRef}
          items={getItems()}
          onChangeSlide={handleChangeSlide}
          onChangeVisibility={handleVisibility}
          menuRef={menuRef}
        >
          <Overlay
            title={title}
            description={slide[activeSlide]?.header ? null : header}
            text={slide[activeSlide]?.text?.length ? null : text}
            wideMenu={wideMenu}
          />
          <SlidesList>
            {slide.map((slideItem, index: number) => (
              <Slide
                key={`slider-${slideItem.id}`}
                index={index}
                {...slideItem}
                ref={slideRefs.current[index]}
                videoRef={videoRefs.current[index]}
              />
            ))}
          </SlidesList>
        </ScrollableSlider>
      </Wrapper>
    </Container>
  );
};

export default WidgetFive;
