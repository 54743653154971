import styled from '@emotion/styled';

import { Layout, GatsbyPicture } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

export const Container = styled(Layout)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 0;
  }
`;

export const Wrapper = styled(Layout)`
  grid-column: 1/-1;
  grid-template-areas:
    't t t t . . l1 l2 l3 l4'
    '. . . . . . d d d d';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      't  t  t  t  t  t'
      '.  .  l1 l2 l3 l4'
      '.  .  d  d  d  d';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-areas:
      't  t  t  t'
      'l1 l2 l3 l4'
      'd  d  d  d';
  }
`;

export const Title = styled.h3`
  grid-area: t;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-bottom: 2rem;
  }
`;

export const Description = styled.p`
  grid-area: d;
`;

export const Picture = styled(GatsbyPicture)`
  grid-column: 1/-1;
`;

export const Logo = styled.img`
  max-height: 5.5rem;
  align-self: center;
  width: auto;
  max-width: 100%;

  &:nth-of-type(1) { grid-area: l1; }
  &:nth-of-type(2) { grid-area: l2; }
  &:nth-of-type(3) { grid-area: l3; }
  &:nth-of-type(4) { grid-area: l4; }
`;
