import styled from '@emotion/styled';

import { GatsbyVideo, GatsbyPicture } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

export const Picture = styled(GatsbyPicture)`
  height: 50vh;
  width: 100%;

  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 25vh;
  }
`;

export const Video = styled(GatsbyVideo)`
  height: 50vh;
  min-width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;

  > video {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 25vh;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  display: grid;
  align-content: end;
  bottom: 0;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  height: 100%;
  width: 100%;

  &.active {
    opacity: 1;
    pointer-events: auto;
    will-change: opacity pointer-events;
  }
`;
