import styled from '@emotion/styled';

import {
  GatsbyPicture, Layout, MiddleText, TouchSlider,
} from 'UI';
import {
  MIN_4K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Wrapper as TouchWrapper } from 'UI/TouchSlider/TouchSlider.styled';

export const Container = styled(Layout)`
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }
`;

export const Wrapper = styled(Layout)`
  grid-column: 1/-1;
`;

export const Title = styled.h3`
  grid-column: 1/-1;
`;

export const Slider = styled(TouchSlider)`
  grid-column: 1/-1;

  ${TouchWrapper} {
    gap: 0;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      gap: 0;
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      gap: 0;
    }

    @media (min-width: ${MIN_4K}px) {
      gap: 0;
    }
  }
`;

export const Picture = styled(GatsbyPicture)`
  > img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
`;

export const Description = styled(MiddleText)`
  grid-column: 1/7;
`;
