import styled from '@emotion/styled';

import {
  GatsbyPicture, Layout, MiddleText, SectionTitle,
} from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

export const Wrapper = styled(Layout)`
  row-gap: 3rem;
  grid-template-areas:
    'h h h h s s s s s s'
    '. . . . s s s s s s';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'h h s s s s'
      '. . s s s s';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-areas:
      'h h h h'
      's s s s';
  }
`;

export const Header = styled(SectionTitle)`
  grid-area: h;
`;

export const SlideItem = styled.div`
  grid-area: s;
  grid-row: span 1;
  display: grid;
  gap: 0.8rem;
  grid-auto-flow: row;
`;

export const SlideTitle = styled(MiddleText)`
`;

export const Picture = styled(GatsbyPicture)`
  grid-column: 1/-1;
  aspect-ratio: 2/1;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
