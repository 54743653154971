import React, { FC } from 'react';

import { IComponentWidgetGallery } from 'interfaces/widgets';

import {
  Wrapper, Title, Slider, Container, Picture, Description,
} from './Gallery.styled';

const Gallery: FC<IComponentWidgetGallery> = ({ title, gallery, description }) => {
  if (!gallery?.length) return null;

  return (
    <Container gridOnly widePaddings>
      <Wrapper verticalOnly>
        <Title>{title}</Title>
      </Wrapper>
      <Slider
        items={gallery?.map(({ id }) => `${id}`)}
        itemWidth={80}
      >
        {gallery?.map((item) => (
          <Picture
            key={item.id}
            {...item}
          />
        ))}
      </Slider>
      {description && (
        <Wrapper withPaddings>
          <Description>{description}</Description>
        </Wrapper>
      )}
    </Container>
  );
};

export default Gallery;
