import styled from '@emotion/styled';

import { Layout } from 'UI';

export const Wrapper = styled(Layout)`
  row-gap: 3rem;
`;

export const ResultItem = styled.h3`
  grid-column: span 4;
  position: relative;
  padding-left: 1rem;

  ::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0.2em;
    height: 0.8em;
    width: 1px;
    background: var(--red);
    margin-right: 1rem;
  }
`;
