import styled from '@emotion/styled';
import { Link } from 'gatsby';

import {
  SectionTitle, Layout, GatsbyPicture, GatsbyVideo,
} from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import { ButtonStyle } from 'UI/Button/styled';

export const Wrapper = styled(Layout)`
  grid-template-areas:
    't t t t t t t t t t'
    'h h h h h h h h . .'
    'p p . . . . d d d d'
    '. . . . . . . . . .'
    'i i i i i i i i i i';

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      grid-template-areas:
        't t t t t t'
        'h h h h h h'
        '. . d d d d'
        '. . . . p p'
        'i i i i i i';
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      grid-template-areas:
        't t t t'
        'h h h h'
        'd d d d'
        'p p . .'
        'i i i i';
    }
`;

export const Title = styled(SectionTitle)`
  grid-area: t;
`;

export const Header = styled.h2`
  grid-area: h;
  margin-bottom: 8rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-bottom: 2rem;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 0;
  }
`;

export const PressReleaseButton = styled(Link)`
  ${ButtonStyle}

  grid-area: p;
`;

export const Description = styled.p`
  grid-area: d;
`;

export const Preview = styled(GatsbyPicture)`
  grid-area: i;
`;

export const Video = styled(GatsbyVideo)`
  grid-area: i;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
`;
