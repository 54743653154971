import styled from '@emotion/styled';

import { Layout, MiddleText, SectionTitle } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

export const Wrapper = styled(Layout)`
  grid-column: 1/-1;
  grid-template-areas:
    't t t t s s d d d d'
    '. . . . a a b b b b'
    '. . . . a a b b b b';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      't t d d d d'
      '. . a b b b'
      '. . a b b b';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-areas:
      't t t t'
      'd d d d'
      'a a a a'
      'b b b b';
  }
`;

export const Space = styled.div`
  grid-area: s;
`;

export const Title = styled.h3`
  grid-area: t;
`;

export const Description = styled.p`
  grid-area: d;
  white-space: pre-line;
`;

export const TextTitle = styled(SectionTitle)`
  grid-area: a;
  grid-row: span 1;
  margin-top: 4rem;
  margin-bottom: 0;
`;

export const TextWrapper = styled.div`
  margin-top: 4rem;
  grid-area: b;
  grid-row: span 1;
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
  }
`;

export const TextDescription = styled(MiddleText)`
  ::before {
    content: '';
    display: inline-block;
    height: 1.2rem;
    width: 1px;
    background: var(--red);
    margin-right: 1rem;
    margin-bottom: -0.1rem;
  }
`;
