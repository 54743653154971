import styled from '@emotion/styled';

import { Layout, MiddleText } from 'UI';
import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const Container = styled(Layout)`
  position: absolute;
  width: 100%;
  top: 0;
  height: 50vh;
  color: var(--white);
  z-index: 1;
  pointer-events: none;
  align-items: start;

  > * {
    pointer-events: auto;
  }
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 75vh;
  }
`;

export const Title = styled.p`
  grid-row: 1;
  grid-column: 1/5;
  margin-top: 10px;
  font-weight: 500;

  &.wideMenu {
    @media (max-width: 1600px) {
      margin-top: 4rem;
    }
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      margin-top: 10px;
    }
  }
`;

export const Description = styled.p`
  grid-column: 1/5;
  align-self: end;
  grid-row: 2;
`;

export const TextWrapper = styled.div`
  grid-column: 1/5;
  align-self: end;
  grid-row: 3;
`;

export const TextTitle = styled(MiddleText)`
  padding-left: 1rem;
  position: relative;
  margin-bottom: 0.6rem;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.55rem;
    left: 0;
    height: 1.2rem;
    width: 1px;
    background: var(--red);
    margin-right: 1rem;
    line-height: 1em;
  }
`;
