import styled from '@emotion/styled';

import { Layout, GatsbyPicture } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import Markdown from 'markdown-to-jsx';

export const Container = styled(Layout)`
  position: relative;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Wrapper = styled(Layout)`
  position: absolute;
  z-index: 1;
  grid-column: 1/-1;
  grid-template-areas:
    't t t t . . . . . .'
    'd d d d . . . . . .';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    position: relative;
    grid-template-areas:
      't t t t . .'
      'd d d d . .';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    position: absolute;
    top: 0;
    padding-top: 0;
    grid-template-areas:
      't t t t'
      'd d d d';
  }
`;

export const Title = styled.h3`
  grid-area: t;
  margin-top: 3rem;

  @media (min-width: ${MAX_MOB_WIDTH}px) {
    min-width: 11em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
  }
`;

export const Description = styled(Markdown)`
  grid-area: d;
`;

export const PictureDesktop = styled(GatsbyPicture)`
  grid-column: 1/-1;
  position: relative;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  > img {
    object-fit: cover;
  }
`;

export const Picture = styled(PictureDesktop)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

export const PictureMobile = styled(GatsbyPicture)`
  position: relative;
  display: none;
  grid-column: 1/-1;

  > img {
    object-fit: cover;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: block;
  }
`;
