import React, { forwardRef, Ref } from 'react';

import { IMediaImage, IMediaVideo } from 'interfaces/media';
import { IComponentWidgetSlide } from 'interfaces/widgets';

import Overlay from '../Overlay/Overlay';

import { Wrapper, Video, Picture } from './Slide.styled';

interface ISlide extends IComponentWidgetSlide<IMediaVideo | IMediaImage> {
  index: number;
  videoRef: Ref<HTMLVideoElement>;
  wideMenu?: boolean;
}

const VideoItem = forwardRef<HTMLVideoElement, IMediaVideo>((media, ref) => (
  <Video
    ref={ref}
    media={media as IMediaVideo}
    loop
    muted
    playOnVisible={false}
  />
));

const Slide = forwardRef<HTMLDivElement, ISlide>(({
  header,
  media,
  videoRef,
  wideMenu,
}, ref) => (
  <>
    <Wrapper ref={ref}>
      <Overlay
        description={header}
        wideMenu={wideMenu}
      />
      {media?.mime.includes('video') ? (
        <VideoItem ref={videoRef} {...media as IMediaVideo} />
      ) : (
        <Picture {...media as IMediaImage} />
      )}
    </Wrapper>
  </>
));

export default Slide;
