import React, { FC } from 'react';

import { IComponentWidgetLink } from 'interfaces/widgets';

import {
  Wrapper, LinkContainer, Header, LinkTitle, Date, Logo, Link,
} from './MediaCoverage.styled';

interface IMediaCoverage {
  title: string;
  links: IComponentWidgetLink[];
}

const MediaCoverage: FC<IMediaCoverage> = ({ title, links }) => (
  <Wrapper widePaddings>
    <Header>{title}</Header>
    {links.map(({
      id, text, link, logo, date,
    }) => (
      <LinkContainer key={id}>
        {logo?.localFile.publicURL && (
          <Logo src={logo?.localFile.publicURL} alt={text} />
        )}
        <LinkTitle>
          <Link href={link} target="_blank" rel="noreferrer">{text}</Link>
        </LinkTitle>
        <Date>{date}</Date>
      </LinkContainer>
    ))}
  </Wrapper>
);

export default MediaCoverage;
