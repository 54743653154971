import React, { FC } from 'react';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Wrapper, Header, SlideItem, Picture, SlideTitle,
} from './WidgetSeven.styled';

const WidgetSeven: FC<IWidget<null, IMediaImage>> = ({
  title, slide,
}) => (
  <Wrapper withPaddings widePaddings>
    <Header>{title}</Header>
    {slide.map((item) => (
      <SlideItem key={item.id}>
        <Picture {...item.media} />
        <SlideTitle>{item.title}</SlideTitle>
      </SlideItem>
    ))}
  </Wrapper>
);

export default WidgetSeven;
