import React, { FC } from 'react';

import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, ResultItem,
} from './WidgetSix.styled';

const WidgetSix: FC<IWidget<null, null>> = ({
  text,
}) => (
  <Wrapper withPaddings widePaddings>
    {text.map(({ title, id }) => (
      <ResultItem key={id}>{title}</ResultItem>
    ))}
  </Wrapper>
);

export default WidgetSix;
