import styled from '@emotion/styled';

import { Layout, GatsbyPicture, GatsbyVideo } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';

export const Wrapper = styled(Layout)`
  grid-template-areas:
    't t t t . . . . . .'
    '. . . . . . d d d d'
    '. . . . . . . . . .'
    'p p p p p p p p p p';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      't t t t t t'
      '. . d d d d'
      '. . . . . .'
      'p p p p p p';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 10px;
    grid-template-areas:
      't t t t'
      'd d d d'
      'p p p p';
  }
`;

export const Title = styled.h3`
  grid-area: t;
`;

export const Description = styled.p`
  grid-area: d;
  white-space: pre-line;
`;

export const Picture = styled(GatsbyPicture)`
  grid-area: p;
`;

export const Video = styled(GatsbyVideo)`
  grid-area: p;
`;
