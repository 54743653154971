import React, { FC } from 'react';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage, IMediaVideo } from 'interfaces/media';

import {
  Wrapper, Title, Description, Picture, Video,
} from './WidgetOne.styled';

const WidgetOne: FC<IWidget<IMediaImage | IMediaVideo, null>> = ({
  title, header, media,
}) => (
  <Wrapper withPaddings widePaddings>
    <Title>{title}</Title>
    {header && (<Description>{header}</Description>)}
    {media?.[0]?.id && (media?.[0]?.mime.includes('video') ? (
      <Video media={media[0] as IMediaVideo} loop />
    ) : (
      <Picture {...media[0] as IMediaImage} />
    ))}
  </Wrapper>
);

export default WidgetOne;
