import React, { FC } from 'react';

import { IWidget } from 'interfaces/widgets';
import { IMediaFile, IMediaImage } from 'interfaces/media';

import {
  Wrapper, Title, Description, Picture, Container, Logo,
} from './WidgetThree.styled';

const WidgetThree: FC<IWidget<IMediaImage, IMediaFile>> = ({
  title, header, media, slide,
}) => (
  <Container gridOnly>
    <Wrapper withPaddings widePaddings>
      <Title>{title}</Title>
      {slide.map((item) => (
        <Logo key={item.id} src={item?.media?.localFile.publicURL} alt={item.title} />
      ))}
      <Description>{header}</Description>
    </Wrapper>

    {media?.[0]?.id && (
      <Picture {...media[0]} />
    )}
  </Container>
);

export default WidgetThree;
