import React, { FC } from 'react';

import { IStrapiEvent } from 'interfaces/events';
import { IMediaImage } from 'interfaces/media';

import {
  Title, Header, Wrapper, PressReleaseButton, Preview, Video, Description,
} from './EventsWelcome.styled';

type IEventsWelcome = IStrapiEvent<IMediaImage, IMediaImage>;

const EventsWelcome: FC<IEventsWelcome> = ({
  header,
  title,
  press_release,
  preview,
  video,
  description,
}) => (
  <Wrapper withPaddings>
    <Title>{title}</Title>
    <Header>{header}</Header>
    {press_release && (
      <PressReleaseButton to={`/press-releases/${press_release.slug}`}>
        View press release
      </PressReleaseButton>
    )}
    {description && (
      <Description>{description}</Description>
    )}
    {video && (
      <Video
        media={video}
        poster={preview?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src}
        playOnVisible={false}
        playButton
      />
    )}
    {(preview && !video) && (
      <Preview {...preview} />
    )}
  </Wrapper>
);

export default EventsWelcome;
