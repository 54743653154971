import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout } from 'UI';
import {
  Container as MenuContainer,
  Wrapper as MenuWrapper,
  ScrollPointer,
} from 'UI/ScrollableSlider/SliderMenu/styled';

export const Container = styled(Layout)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  grid-column: 1/-1;

  ${MenuContainer} {
    justify-content: end;
    right: 0;
    left: auto;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    ${MenuContainer} {
      right: auto;
      left: 0;
      top: 5rem;
    }

    ${MenuWrapper} {
      > * {
        justify-self: start;
      }
    }

    ${ScrollPointer} {
      right: auto;
      left: -10px;
    }
  }
`;

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #000;
`;
