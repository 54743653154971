import styled from '@emotion/styled';

import { Layout, MiddleText } from 'UI';
import {
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
  MIN_2K,
  MIN_4K,
} from 'constants/sizes';
import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';

export const Wrapper = styled(Layout)`
  column-gap: 40px;
  row-gap: 5rem;

  @media (min-width: ${MIN_2K}px) {
    padding-bottom: 0;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-bottom: 0;
  }
`;

export const Header = styled.h3`
  grid-row: 1;
  grid-column: 1/4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-bottom: 2rem;
    grid-column: 1/-1;
  }
`;

export const LinkContainer = styled.div`
  grid-column: 5/8;
  display: grid;
  grid-template-rows: 2rem 1fr max-content;
  grid-auto-flow: row;
  gap: 1rem;
  align-content: start;
  align-items: start;

  :nth-of-type(2n) {
    grid-column: 8/11;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
    margin-bottom: 2rem;

    :nth-of-type(2n) {
      grid-column: 4/-1;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;

    :nth-of-type(2n) {
      grid-column: 1/-1;
    }
  }
`;

export const Logo = styled.img`
  align-self: center;
  justify-self: start;
  max-height: 100%;
  height: 2rem;
  width: auto;
  opacity: 0.4;
`;

export const LinkTitle = styled(MiddleText)``;

export const Link = styled.a`
  :hover {
    ${ArrowLinkStyles}
  }
`;

export const Date = styled.span``;
