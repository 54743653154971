import React, { FC } from 'react';
import { PageProps, graphql } from 'gatsby';

import Head from 'components/Layout/Head/Head';

import { IWidget } from 'interfaces/widgets';
import { IStrapiEvent } from 'interfaces/events';
import { IMediaImage, IMediaVideo, IMediaFile } from 'interfaces/media';

import EventsWelcome from 'components/blocks/Events/EventsWelcome/EventsWelcome';
import MediaCoverage from 'components/blocks/Events/MediaCoverage/MediaCoverage';

import WidgetOne from 'components/blocks/Events/WidgetOne/WidgetOne';
import WidgetTwo from 'components/blocks/Events/WidgetTwo/WidgetTwo';
import WidgetThree from 'components/blocks/Events/WidgetThree/WidgetThree';
import Gallery from 'components/blocks/Events/Gallery/Gallery';
import WidgetFour from 'components/blocks/Events/WidgetFour/WidgetFour';
import WidgetFive from 'components/blocks/Events/WidgetFive/WidgetFive';
import WidgetSix from 'components/blocks/Events/WidgetSix/WidgetSix';
import WidgetSeven from 'components/blocks/Events/WidgetSeven/WidgetSeven';
import WidgetEight from 'components/blocks/Events/WidgetEight/WidgetEight';

interface IPressReleasePage extends PageProps {
  data: {
    strapiEvents: IStrapiEvent<IMediaImage, IMediaImage | IMediaVideo>;
  };
}

const EventPage: FC<IPressReleasePage> = ({ data: { strapiEvents } }) => {
  const {
    title, header, widgets, gallery, media_coverage: mediaCoverage, slug, preview,
  } = strapiEvents;

  return (
    <>
      <Head
        title={title}
        description={header}
        preview={preview}
        slug={`events/${slug}`}
      />
      <EventsWelcome
        {...strapiEvents as IStrapiEvent<IMediaImage, IMediaImage>}
      />
      {widgets?.map((widget) => {
        switch (widget.widget_name) {
          case 'one':
            return <WidgetOne key={widget.id} {...widget as IWidget<IMediaImage, null>} />;
          case 'two':
            return <WidgetTwo key={widget.id} {...widget as IWidget<IMediaImage, null>} />;
          case 'three':
            return <WidgetThree key={widget.id} {...widget as IWidget<IMediaImage, IMediaFile>} />;
          case 'four':
            return <WidgetFour key={widget.id} {...widget as IWidget<IMediaImage, null>} />;
          case 'five':
            return (
              <WidgetFive
                key={widget.id}
                {...widget as IWidget<null, IMediaImage | IMediaVideo>}
              />
            );
          case 'six':
            return <WidgetSix key={widget.id} {...widget as IWidget<null, null>} />;
          case 'seven':
            return <WidgetSeven key={widget.id} {...widget as IWidget<null, IMediaImage>} />;
          case 'eight':
            return <WidgetEight key={widget.id} {...widget as IWidget<null, null>} />;
          default:
            return null;
        }
      })}
      <Gallery {...gallery} />
      {Boolean(mediaCoverage?.length) && (
        <MediaCoverage title="Media Coverage" links={mediaCoverage} />
      )}
    </>
  );
};

export const query = graphql`
  query($slug: String) {
    strapiEvents(slug: { eq: $slug }) {
      header
      title
      description
      slug
      gallery {
        gallery {
          ...MediaImageFragment
        }
        description
        title
      }
      widgets {
        ...StrapiComponentWidgetFragment
      }
      preview {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 98)
          }
          publicURL
        }
      }
      video {
        ...MediaVideoFragment
      }
      press_release {
        slug
      }
      media_coverage {
        ...StrapiWidgetLinkFragment
      }
    }
  }
`;

export default EventPage;
