import React, { FC } from 'react';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Wrapper, Title, Description, Picture, Container, PictureMobile, PictureDesktop,
} from './WidgetTwo.styled';

const WidgetFour: FC<IWidget<IMediaImage, null>> = ({
  title, header, media,
}) => (
  <Container gridOnly withPaddings>
    <Wrapper withPaddings>
      <Title>{title}</Title>
      <Description>{header}</Description>
    </Wrapper>

    {(media?.[0]?.id && !media?.[1]?.id) && (
      <PictureDesktop {...media[0]} />
    )}
    {(media?.[0]?.id && media?.[1]?.id) && (
      <Picture {...media[0]} />
    )}
    {media?.[1]?.id && (
      <PictureMobile {...media[1]} />
    )}
  </Container>
);

export default WidgetFour;
